const KANZLEI = 3255;
export { KANZLEI };

const VERSION_MAJOR = 1;
const VERSION_MINOR = 2;
const VERSION_PATCH = 51;

export { VERSION_MAJOR, VERSION_MINOR, VERSION_PATCH };

const REGFORM_SITEINDEX_START = 'registrieren-daten';
const DIREKTREGFORM_SITEINDEX_START = 'direktregistrieren-daten';
const REGFORM_SITEINDEX_PW = 'registrieren-passwort';
const DIREKTREGFORM_SITEINDEX_PW = 'direktregistrieren-passwort';
const REGFORM_SITEINDEX_PWSUCCESS = 'registrieren-passwort-success';
const REGFORM_SITEINDEX_ERROR = 'registrieren-error';
const DIREKTREGFORM_SITEINDEX_ERROR = 'direktregistrieren-error';
const REGFORM_SITEINDEX_EMAIL = 'registrieren-mail';
const DIREKTREGFORM_SITEINDEX_EMAIL = 'direktregistrieren-mail';
const REGFORM_SITEINDEX_SUCCESSTOKEN = 'registrieren-2fa';
const DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN = 'direktregistrieren-2fa';
const REGFORM_SITEINDEX_CHANGEPHONENUMBER = 'registrieren-telefonnummer';
const REGFORM_SITEINDEX_LOGINSUCCESS = 'registrieren-success';
const DIREKTREGFORM_SITEINDEX_LOGINSUCCESS = 'direktregistrieren-success';
const REGFORM_SITEINDEX_CONFIRMERROR = 'registrieren-error';
const REGFORM_SITEINDEX_PWFORGETTOKEN = 'vergessen-neu';
const REGFORM_SITEINDEX_INFO_PWFORGETTOKEN = 'vergessen-2fa';
const REGFORM_SITEINDEX_INFO_MOBILNUMMER = 'registrieren-info-mobilnummer';
const DIREKTREGFORM_SITEINDEX_INFO_MOBILNUMMER = 'direktregistrieren-info-mobilnummer';
const REGFORM_SITEINDEX_INFO_TOKEN = 'registrieren-info-2fa';
const DIREKTREGFORM_SITEINDEX_INFO_TOKEN = 'direktregistrieren-info-2fa';
const REGFORM_SITEINDEX_EMAIL_BESTAETIGEN = 'registrieren-mail-confirm';
const REGFORM_SITEINDEX_ALMOSTTHERE = 'registrieren-fast-fertig';
const REGFORM_SITEINDEX_LINKEXPIRED = 'registrieren-link-abgelaufen';
const REGFORM_SITEINDEX_USEREXISTS = 'registrieren-user-existiert';

export {
  REGFORM_SITEINDEX_START,
  DIREKTREGFORM_SITEINDEX_START,
  REGFORM_SITEINDEX_PW,
  DIREKTREGFORM_SITEINDEX_PW,
  REGFORM_SITEINDEX_PWSUCCESS,
  REGFORM_SITEINDEX_EMAIL,
  DIREKTREGFORM_SITEINDEX_EMAIL,
  REGFORM_SITEINDEX_ERROR,
  DIREKTREGFORM_SITEINDEX_ERROR,
  REGFORM_SITEINDEX_SUCCESSTOKEN,
  DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN,
  REGFORM_SITEINDEX_CHANGEPHONENUMBER,
  REGFORM_SITEINDEX_CONFIRMERROR,
  REGFORM_SITEINDEX_PWFORGETTOKEN,
  REGFORM_SITEINDEX_INFO_PWFORGETTOKEN,
  REGFORM_SITEINDEX_INFO_MOBILNUMMER,
  DIREKTREGFORM_SITEINDEX_INFO_MOBILNUMMER,
  REGFORM_SITEINDEX_INFO_TOKEN,
  DIREKTREGFORM_SITEINDEX_INFO_TOKEN,
  REGFORM_SITEINDEX_LOGINSUCCESS,
  DIREKTREGFORM_SITEINDEX_LOGINSUCCESS,
  REGFORM_SITEINDEX_EMAIL_BESTAETIGEN,
  REGFORM_SITEINDEX_ALMOSTTHERE,
  REGFORM_SITEINDEX_LINKEXPIRED,
  REGFORM_SITEINDEX_USEREXISTS,
};

const LOGINFORM_SITEINDEX_START = 'login';
const LOGINFORM_SITEINDEX_SUCCESSTOKEN = 'login-2fa';
const LOGINFORM_SITEINDEX_LOGINSUCCESS = 'login-success';
const LOGINFORM_SITEINDEX_ERROR = 'login-error';
const LOGINFORM_SITEINDEX_PWFORGET = 'vergessen';
const LOGINFORM_SITEINDEX_PWFORGETEMAIL = 'vergessen-mail';

export { LOGINFORM_SITEINDEX_START, LOGINFORM_SITEINDEX_SUCCESSTOKEN, LOGINFORM_SITEINDEX_LOGINSUCCESS, LOGINFORM_SITEINDEX_ERROR, LOGINFORM_SITEINDEX_PWFORGET, LOGINFORM_SITEINDEX_PWFORGETEMAIL };

const SEARCHPROFILE_SITEINDEX_START = 'suchprofil-anlegen';
const SEARCHPROFILE_SITEINDEX_EDIT = 'suchprofil-bearbeiten';
const SEARCHPROFILE_SITEINDEX_USERDATA = 'suchprofil-daten';
const SEARCHPROFILE_SITEINDEX_EMAIL = 'suchprofil-danke';
const SEARCHPROFILE_STATE_ACTIVE = 'active';
const SEARCHPROFILE_STATE_INACTIVE = 'inactive';

export { SEARCHPROFILE_SITEINDEX_START, SEARCHPROFILE_SITEINDEX_EDIT, SEARCHPROFILE_SITEINDEX_USERDATA, SEARCHPROFILE_SITEINDEX_EMAIL, SEARCHPROFILE_STATE_ACTIVE, SEARCHPROFILE_STATE_INACTIVE };

const KENNZAHL_LABEL_LEISURE = 'Freizeit';
const KENNZAHL_LABEL_HEALTH = 'Gesundheit';
const KENNZAHL_LABEL_SHOPPING = 'Einkaufen';
const KENNZAHL_LABEL_CATERING = 'Gastronomie';
const KENNZAHL_LABEL_FAMILY = 'Bildung';
const KENNZAHL_LABEL_AGEGROUPS = 'Alterstruktur';
const KENNZAHL_LABEL_HOUSEHOLDCHILDREN = 'Haushalte mit Kindern';
const KENNZAHL_LABEL_PHASEOFLIFE = 'Lebensphasen';
const KENNZAHL_LABEL_HOUSINGTYPE = 'Wohnformen';
const KENNZAHL_LABEL_DAYSONMARKET = 'Vermarktungsdauer';
const KENNZAHL_LABEL_VALUATION = 'Wertentwicklung';

const kennzahlenLabelsArray = [];
kennzahlenLabelsArray['leisure'] = KENNZAHL_LABEL_LEISURE;
kennzahlenLabelsArray['health'] = KENNZAHL_LABEL_HEALTH;
kennzahlenLabelsArray['shopping'] = KENNZAHL_LABEL_SHOPPING;
kennzahlenLabelsArray['catering'] = KENNZAHL_LABEL_CATERING;
kennzahlenLabelsArray['family'] = KENNZAHL_LABEL_FAMILY;
kennzahlenLabelsArray['householdChildren'] = KENNZAHL_LABEL_HOUSEHOLDCHILDREN;
kennzahlenLabelsArray['ageGroups'] = KENNZAHL_LABEL_AGEGROUPS;
kennzahlenLabelsArray['phaseOfLife'] = KENNZAHL_LABEL_PHASEOFLIFE;
kennzahlenLabelsArray['housingType'] = KENNZAHL_LABEL_HOUSINGTYPE;

kennzahlenLabelsArray['housingType'] = KENNZAHL_LABEL_HOUSINGTYPE;
kennzahlenLabelsArray['days_on_market'] = KENNZAHL_LABEL_DAYSONMARKET;
kennzahlenLabelsArray['valuation'] = KENNZAHL_LABEL_VALUATION;

export {
  KENNZAHL_LABEL_LEISURE,
  KENNZAHL_LABEL_HEALTH,
  KENNZAHL_LABEL_SHOPPING,
  KENNZAHL_LABEL_CATERING,
  KENNZAHL_LABEL_FAMILY,
  KENNZAHL_LABEL_AGEGROUPS,
  KENNZAHL_LABEL_HOUSEHOLDCHILDREN,
  KENNZAHL_LABEL_PHASEOFLIFE,
  KENNZAHL_LABEL_HOUSINGTYPE,
  KENNZAHL_LABEL_DAYSONMARKET,
  KENNZAHL_LABEL_VALUATION,
  kennzahlenLabelsArray,
};

const WOHNWERTLIGHT_SITEINDEX_FORM = 'wohnwert-berechnung';
const WOHNWERTLIGHT_SITEINDEX_RESULT = 'wohnwert-ergebnis';
const WOHNWERTLIGHT_SITEINDEX_ZUSTAND = 'wohnwert-zustand';
const WOHNWERTLIGHT_SITEINDEX_BASIS = 'wohnwert-basis';

export { WOHNWERTLIGHT_SITEINDEX_FORM, WOHNWERTLIGHT_SITEINDEX_RESULT, WOHNWERTLIGHT_SITEINDEX_ZUSTAND, WOHNWERTLIGHT_SITEINDEX_BASIS };

const MARKTINFO_SITEINDEX_FORM = 'berechnen';
const MARKTINFO_SITEINDEX_RESULT = 'ergebnis';

export { MARKTINFO_SITEINDEX_FORM, MARKTINFO_SITEINDEX_RESULT };

const CONTACTFORM_ANLIEGEN_ALLG = 0;
const CONTACTFORM_ANLIEGEN_SUPPORT = 1;
const CONTACTFORM_ANLIEGEN_IMMOGUTACHTEN = 2;
const CONTACTFORM_ANLIEGEN_BERATUNG = 3;
const CONTACTFORM_ANLIEGEN_VERKAUF = 4;
const CONTACTFORM_ANLIEGEN_SUCHE = 5;
const CONTACTFORM_ANLIEGEN_PROFIL = 6;
const CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN = 7;
const CONTACTFORM_ANLIEGEN_MARKTBERICHT = 99;

const CONTACTFORM_SITEINDEX_MAIN = 'kontakt-anfrage';
const CONTACTFORM_SITEINDEX_TERMINWUNSCH = 'kontakt-termin';
const CONTACTFORM_SITEINDEX_IHREIMMOBILIE = 'kontakt-immobilie';
const CONTACTFORM_SITEINDEX_KONTAKTIEREN = 'kontakt-nachricht';
const CONTACTFORM_SITEINDEX_EMAILSENT = 'kontakt-danke';
const CONTACTFORM_SITEINDEX_INFO = 'kontakt';
const CONTACTFORM_SITEINDEX_INFO_BUNDESLAND = 'kontakt-info-bundesland';

export {
  CONTACTFORM_ANLIEGEN_ALLG,
  CONTACTFORM_ANLIEGEN_MARKTBERICHT,
  CONTACTFORM_ANLIEGEN_IMMOGUTACHTEN,
  CONTACTFORM_ANLIEGEN_VERKAUF,
  CONTACTFORM_ANLIEGEN_BERATUNG,
  CONTACTFORM_ANLIEGEN_SUCHE,
  CONTACTFORM_ANLIEGEN_PROFIL,
  CONTACTFORM_ANLIEGEN_SUPPORT,
  CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN,
  CONTACTFORM_SITEINDEX_MAIN,
  CONTACTFORM_SITEINDEX_TERMINWUNSCH,
  CONTACTFORM_SITEINDEX_IHREIMMOBILIE,
  CONTACTFORM_SITEINDEX_KONTAKTIEREN,
  CONTACTFORM_SITEINDEX_EMAILSENT,
  CONTACTFORM_SITEINDEX_INFO,
  CONTACTFORM_SITEINDEX_INFO_BUNDESLAND,
};

const FINANZRECHNER_SITEINDEX_FORM = 0;
const FINANZRECHNER_SITEINDEX_RESULTS = 1;

export { FINANZRECHNER_SITEINDEX_FORM, FINANZRECHNER_SITEINDEX_RESULTS };

// const ROLE_VENDOR = "vendor";
const ROLE_VENDOR = 1;
const ROLE_ABGEBER_NAME = 'Abgeber';
const ROLE_INTERESSENT_NAME = 'Interessent';

export { ROLE_VENDOR, ROLE_ABGEBER_NAME, ROLE_INTERESSENT_NAME };

const IMMO_STATE_AKTIV = 'aktiv';
const IMMO_STATE_INAKTIV = 'inaktiv';
const IMMO_STATE_VERMITTELT = 'vermittelt';
const IMMO_STATE_FREMDVERMITTELT = 'fremdvermittelt';
const IMMO_STATE_RESERVIERT = 'reserviert';
const IMMO_STATE_VERTRAGSERRICHTUNG = 'vertragserrichtung';

export { IMMO_STATE_AKTIV, IMMO_STATE_INAKTIV, IMMO_STATE_VERMITTELT, IMMO_STATE_RESERVIERT, IMMO_STATE_VERTRAGSERRICHTUNG, IMMO_STATE_FREMDVERMITTELT };

const OBJECTREQUESTFORM_SITEINDEX_START = 'anfrage';
const OBJECTREQUESTFORM_SITEINDEX_WIDERRUF = 'widerrufserklaerung';
const OBJECTREQUESTFORM_SITEINDEX_SUCCESS = 'danke';

export { OBJECTREQUESTFORM_SITEINDEX_START, OBJECTREQUESTFORM_SITEINDEX_WIDERRUF, OBJECTREQUESTFORM_SITEINDEX_SUCCESS };

const PROZESS_SITEINDEX_START = 'prozess';

export { PROZESS_SITEINDEX_START };

const KAUFANBOT_SITEINDEX_MAIN = 'kaufanbot-abgeben';
const KAUFANBOT_SITEINDEX_SUCCES = 'kaufanbot-success';

export { KAUFANBOT_SITEINDEX_MAIN, KAUFANBOT_SITEINDEX_SUCCES };

const AKTUELLES_FILTER_ALLE = 0;
const AKTUELLES_FILTER_NACHRICHTEN = 1;
const AKTUELLES_FILTER_BESICHTIGUNGEN = 2;
const AKTUELLES_FILTER_WEITERE = 3;
const AKTUELLES_FILTER_DOKUMENTE = 4;

export { AKTUELLES_FILTER_ALLE, AKTUELLES_FILTER_NACHRICHTEN, AKTUELLES_FILTER_DOKUMENTE, AKTUELLES_FILTER_BESICHTIGUNGEN, AKTUELLES_FILTER_WEITERE };

const UEBERGABE_CARDTYPE_SAMPLE = 'sample';
const UEBERGABE_CARDTYPE_PENDING = 'pending';
const UEBERGABE_CARDTYPE_CLOSED = 'finished';

export { UEBERGABE_CARDTYPE_SAMPLE, UEBERGABE_CARDTYPE_PENDING, UEBERGABE_CARDTYPE_CLOSED };

const SATISFACTION_SITEINDEX_Q1 = 'zufrieden-1';
const SATISFACTION_SITEINDEX_Q2 = 'zufrieden-2';
const SATISFACTION_SITEINDEX_Q3 = 'zufrieden-3';
const SATISFACTION_SITEINDEX_Q4 = 'zufrieden-4';
const SATISFACTION_SITEINDEX_Q5 = 'zufrieden-5';

const SATISFACTION_SITEINDEX_MESSAGE1 = 'zufrieden-6';
const SATISFACTION_SITEINDEX_MESSAGE2 = 'zufrieden-7';
const SATISFACTION_SITEINDEX_SUCCESS = 'zufrieden-success';

const SATISFACTION_OPTIONS = ['unhappy', 'neutral', 'happy', 'super_happy'];

export {
  SATISFACTION_SITEINDEX_Q1,
  SATISFACTION_SITEINDEX_Q2,
  SATISFACTION_SITEINDEX_Q3,
  SATISFACTION_SITEINDEX_Q4,
  SATISFACTION_SITEINDEX_Q5,
  SATISFACTION_SITEINDEX_MESSAGE1,
  SATISFACTION_SITEINDEX_MESSAGE2,
  SATISFACTION_SITEINDEX_SUCCESS,
  SATISFACTION_OPTIONS,
};

const RECOMMENDATION_SITEINDEX_START = 'recommendation';
const RECOMMENDATION_SITEINDEX_SUCCESS = 'recommendation-success';

export { RECOMMENDATION_SITEINDEX_START, RECOMMENDATION_SITEINDEX_SUCCESS };
const STATS_FILTER_AKTUELLEWOCHE = { key: 0, label: 'Aktuelle Woche' };
const STATS_FILTER_LETZTE3WOCHEN = { key: 1, label: 'Letzte 3 Wochen' };
const STATS_FILTER_GESAMT = { key: 2, label: 'Gesamtlaufzeit' };

export { STATS_FILTER_AKTUELLEWOCHE, STATS_FILTER_GESAMT, STATS_FILTER_LETZTE3WOCHEN };

const NEW_STATS_FILTER_AKTUELLEWOCHE = { key: 0, label: 'Letze 7 Tage' };
const NEW_STATS_FILTER_GESAMT = { key: 1, label: 'Gesamtlaufzeit' };

export { NEW_STATS_FILTER_AKTUELLEWOCHE, NEW_STATS_FILTER_GESAMT };

const SP_ACTIVE_INDEX = 0;
const SP_INACTIVE_INDEX = 1;

export { SP_ACTIVE_INDEX, SP_INACTIVE_INDEX };

const MENU_VERKAUF = 'verkauf';
const MENU_KAUF = 'kauf';

export { MENU_VERKAUF, MENU_KAUF };

const ACTIVITY_ANBOT_TELEFON_ID = 2;
const ACTIVITY_ANBOT_BRIEF_ID = 3;
const ACTIVITY_EMAIL_ANBOT_JUSTIMMO_ID = 4;
const ACTIVITY_ANBOT_SCHRIFTLICH = 5;
const ACTIVITY_AUSHANG_ID = 6;
const ACTIVITY_BESICHTIGUNG_ID = 8;
const ACTIVITY_INSERAT_ID = 10;
const ACTIVITY_NOTARIATSTERMIN_ID = 11;
const ACTIVITY_OBJEKTSTATUSAENDERUNG_RESERVIERT_ID = 12;
const ACTIVITY_RESERVIERUNG_AUFHEBEN_ID = 13;
const ACTIVITY_VERTRAGSABSCHLUSS_ID = 15;
const ACTIVITY_PREISÄNDERUNG_ID = 22;
const ACTIVITY_NACHTELEFONAT_ID = 23;
const ACTIVITY_INTERESSENTENABSAGE_ID = 25;
const ACTIVITY_SONSTIGE_ID = 26;
const ACTIVITY_SCHRIFTVERKEHR_ID = 27;
const ACTIVITY_ABLAUF_ALLEINVERMITTLUNG_ID = 28;
const ACTIVITY_ABLAUF_VERMITTLUNG_ID = 29;
const ACTIVITY_KAUFANBOT_ID = 30;
const ACTIVITY_MIETANBOT_ID = 31;
const ACTIVITY_INTERNETINSERTION_ID = 32;
const ACTIVITY_VERKAUFSTAFEL_ID = 33;
const ACTIVITY_OBJEKTUEBERGABE_ID = 34;
const ACTIVITY_TELEFONAT_ID = 35;
const ACTIVITY_NACHFRAGE_SCHRIFTLICH_ID = 36;
const ACTIVITY_NACHFRAGE_EMAIL_ID = 37;
const ACTIVITY_BESPRECHUNG_ID = 38;
const ACTIVITY_FINANZIERUNGSBERATUNG_ID = 39;
const ACTIVITY_OBJEKTSTATUSAENDERUNG_ID = 41;
const ACTIVITY_ANFRAGE_ID = 46;
const ACTIVITY_EMAIL_ANBOT_ID = 56;
const ACTIVITY_BEHOERDENWEG_ID = 61;
const ACTIVITY_GEGENANBOT_ID = 125;
const ACTIVITY_FOLDER_ID = 126;
const ACTIVITY_NEWSLETTER_ID = 129;
const ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERKAUFT_ID = 133;
const ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERMIETET_ID = 136;
const ACTIVITY_ABGEBERBRIEF_ID_1 = 227;
const ACTIVITY_ABGEBERBRIEF_ID_2 = 228;
const ACTIVITY_ABGEBERBRIEF_ID_3 = 229;
const ACTIVITY_ABGEBERBRIEF_ID_4 = 230;
const ACTIVITY_ABGEBERBRIEF_ID_5 = 231;
const ACTIVITY_ABGEBERBRIEF_ID_6 = 232;
const ACTIVITY_IWB_INSERAT_ID = 420;
const ACTIVITY_SREAL_MAGAZIN_INSERAT_ID = 460;
const ACTIVITY_ABSAGE_ANFRAGE_ID = 461;
const ACTIVITY_RUECKGABEBRIEF_ID_1 = 463;
const ACTIVITY_RUECKGABEBRIEF_ID_2 = 466;
const ACTIVITY_RUECKGABEBRIEF_ID_3 = 469;
const ACTIVITY_RUECKGABEBRIEF_ID_4 = 472;
const ACTIVITY_RUECKGABEBRIEF_ID_5 = 475;
const ACTIVITY_RUECKGABEBRIEF_ID_6 = 481;
const ACTIVITY_BEWERTUNG_ID = 535;
const ACTIVITY_FACEBOOK_ID = 547;
const ACTIVITY_TWITTER_ID = 550;
const ACTIVITY_GOOGLE_PLUS_ID = 553;
const ACTIVITY_LINKEDIN_ID = 556;
const ACTIVITY_ANBOT_PERSOENLICH_ID = 570;
const ACTIVITY_BESICHTUNGSWUNSCH_ID = 794;
const ACTIVITY_EXPOSE_ID = 834;
const ACTIVITY_EMAIL_ID = 1049;
const ACTIVITY_TERMINEINLADUNG_ID = 1067;
const ACTIVITY_TERMINERINNERUNG_ID = 1070;
const ACTIVITY_ANGEBOTSPROZESS_GESTARTET_ID = 1088;
const ACTIVITY_ANGEBOTSPROZESS_VERLÄNGERT_ID = 1094;
const ACTIVITY_ANGEBOTSPROZESS_BEENDET_ID = 1097;
const ACTIVITY_INTERESSENT_EINGELADEN_ID = 1100;
const ACTIVITY_INTERESSENT_ANGEBOT_ABGEGEBEN_ID = 1103;
const ACTIVITY_SOFORTKAUFANFRAGE_INTERESSENT_ID = 1106;
const ACTIVITY_SOFORTKAUFANFRAGE_ABGELEHNT_ID = 1109;
const ACTIVITY_SOFORTKAUFANFRAGE_BESTAETIGT_ID = 1112;
const ACTIVITY_ANGEBOTSENTFERNUNG_ID = 1115;
const ACTIVITY_NEUER_HOECHSTBIETER_ID = 1118;
const ACTIVITY_ONLINE_BESICHTIGUNG_ID = 1196;
const ACTIVITY_TERMIN_NICHT_STATTGEFUNDEN_ID = 1207;
const ACTIVITY_TERMINWUNSCH_ID = 1210;
const ACTIVITY_BESICHTIGUGN_TUERSYSTEM_ID = 1317;
const ACTIVITY_GEBAUDETUER_MITARBEITER_AUF_ID = 1320;
const ACTIVITY_GEBAUDETUER_MITARBEITER_ZU_ID = 1323;
const ACTIVITY_EINGANGSTUERE_MITARBEITER_AUF_ID = 1326;
const ACTIVITY_EINGANGSTUERE_MITARBEITER_ZU_ID = 1329;
const ACTIVITY_GEBAEUDETUER_INTERESSENT_AUF_ID = 1332;
const ACTIVITY_GEBAEUDETUER_INTERESSENT_ZU_ID = 1335;
const ACTIVITY_EINGANGSTUER_INTERESSENT_AUF_ID = 1338;
const ACTIVITY_EINGANGSTUER_INTERESSENT_ZU_ID = 1341;

export {
  ACTIVITY_NEWSLETTER_ID,
  ACTIVITY_ABGEBERBRIEF_ID_5,
  ACTIVITY_RUECKGABEBRIEF_ID_1,
  ACTIVITY_RUECKGABEBRIEF_ID_2,
  ACTIVITY_RUECKGABEBRIEF_ID_3,
  ACTIVITY_RUECKGABEBRIEF_ID_4,
  ACTIVITY_RUECKGABEBRIEF_ID_5,
  ACTIVITY_RUECKGABEBRIEF_ID_6,
  ACTIVITY_ABGEBERBRIEF_ID_6,
  ACTIVITY_ABGEBERBRIEF_ID_2,
  ACTIVITY_ABGEBERBRIEF_ID_1,
  ACTIVITY_ABGEBERBRIEF_ID_4,
  ACTIVITY_ABGEBERBRIEF_ID_3,
  ACTIVITY_SOFORTKAUFANFRAGE_INTERESSENT_ID,
  ACTIVITY_FOLDER_ID,
  ACTIVITY_GEGENANBOT_ID,
  ACTIVITY_BEHOERDENWEG_ID,
  ACTIVITY_EMAIL_ANBOT_ID,
  ACTIVITY_BEWERTUNG_ID,
  ACTIVITY_ABSAGE_ANFRAGE_ID,
  ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERMIETET_ID,
  ACTIVITY_ANBOT_TELEFON_ID,
  ACTIVITY_ANFRAGE_ID,
  ACTIVITY_EINGANGSTUER_INTERESSENT_ZU_ID,
  ACTIVITY_EINGANGSTUER_INTERESSENT_AUF_ID,
  ACTIVITY_KAUFANBOT_ID,
  ACTIVITY_GEBAEUDETUER_INTERESSENT_ZU_ID,
  ACTIVITY_GEBAEUDETUER_INTERESSENT_AUF_ID,
  ACTIVITY_EINGANGSTUERE_MITARBEITER_ZU_ID,
  ACTIVITY_EMAIL_ANBOT_JUSTIMMO_ID,
  ACTIVITY_EINGANGSTUERE_MITARBEITER_AUF_ID,
  ACTIVITY_GEBAUDETUER_MITARBEITER_ZU_ID,
  ACTIVITY_MIETANBOT_ID,
  ACTIVITY_GEBAUDETUER_MITARBEITER_AUF_ID,
  ACTIVITY_BESICHTIGUGN_TUERSYSTEM_ID,
  ACTIVITY_INTERNETINSERTION_ID,
  ACTIVITY_NEUER_HOECHSTBIETER_ID,
  ACTIVITY_ANGEBOTSENTFERNUNG_ID,
  ACTIVITY_SOFORTKAUFANFRAGE_BESTAETIGT_ID,
  ACTIVITY_VERKAUFSTAFEL_ID,
  ACTIVITY_TERMINWUNSCH_ID,
  ACTIVITY_TERMIN_NICHT_STATTGEFUNDEN_ID,
  ACTIVITY_ONLINE_BESICHTIGUNG_ID,
  ACTIVITY_SONSTIGE_ID,
  ACTIVITY_SOFORTKAUFANFRAGE_ABGELEHNT_ID,
  ACTIVITY_INTERESSENT_EINGELADEN_ID,
  ACTIVITY_ANGEBOTSPROZESS_BEENDET_ID,
  ACTIVITY_OBJEKTUEBERGABE_ID,
  ACTIVITY_ANGEBOTSPROZESS_VERLÄNGERT_ID,
  ACTIVITY_TERMINERINNERUNG_ID,
  ACTIVITY_TERMINEINLADUNG_ID,
  ACTIVITY_TELEFONAT_ID,
  ACTIVITY_EXPOSE_ID,
  ACTIVITY_BESICHTUNGSWUNSCH_ID,
  ACTIVITY_ANBOT_PERSOENLICH_ID,
  ACTIVITY_NACHFRAGE_SCHRIFTLICH_ID,
  ACTIVITY_SCHRIFTVERKEHR_ID,
  ACTIVITY_NACHFRAGE_EMAIL_ID,
  ACTIVITY_BESPRECHUNG_ID,
  ACTIVITY_FINANZIERUNGSBERATUNG_ID,
  ACTIVITY_ABLAUF_ALLEINVERMITTLUNG_ID,
  ACTIVITY_ABLAUF_VERMITTLUNG_ID,
  ACTIVITY_INTERESSENTENABSAGE_ID,
  ACTIVITY_NACHTELEFONAT_ID,
  ACTIVITY_PREISÄNDERUNG_ID,
  ACTIVITY_RESERVIERUNG_AUFHEBEN_ID,
  ACTIVITY_VERTRAGSABSCHLUSS_ID,
  ACTIVITY_OBJEKTSTATUSAENDERUNG_RESERVIERT_ID,
  ACTIVITY_NOTARIATSTERMIN_ID,
  ACTIVITY_ANBOT_SCHRIFTLICH,
  ACTIVITY_ANBOT_BRIEF_ID,
  ACTIVITY_EMAIL_ID,
  ACTIVITY_AUSHANG_ID,
  ACTIVITY_BESICHTIGUNG_ID,
  ACTIVITY_INSERAT_ID,
  ACTIVITY_OBJEKTSTATUSAENDERUNG_ID,
  ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERKAUFT_ID,
  ACTIVITY_IWB_INSERAT_ID,
  ACTIVITY_SREAL_MAGAZIN_INSERAT_ID,
  ACTIVITY_FACEBOOK_ID,
  ACTIVITY_TWITTER_ID,
  ACTIVITY_GOOGLE_PLUS_ID,
  ACTIVITY_LINKEDIN_ID,
  ACTIVITY_INTERESSENT_ANGEBOT_ABGEGEBEN_ID,
  ACTIVITY_ANGEBOTSPROZESS_GESTARTET_ID,
};

const ERR_LOGIN_EXPIRED = 'login-expired';
export { ERR_LOGIN_EXPIRED };

const CONDITION_RENOVATION_NEEDED = 'renovation_needed';
const CONDITION_WELL_MAINTAINED = 'well_maintained';
const CONDITION_NEW_OR_RECENTLY_RENOVATED = 'new_or_recently_renovated';

export { CONDITION_RENOVATION_NEEDED, CONDITION_WELL_MAINTAINED, CONDITION_NEW_OR_RECENTLY_RENOVATED };

const ICONLISTELEMENT_BASIC_LABEL = 'basic';
const ICONLISTELEMENT_MEHRWERT_LABEL = 'mehrwert';
const ICONLISTELEMENT_ALLINONE_LABEL = 'allInOne';
const ICONLISTELEMENT_DIGITALPERSÖNLICH_LABEL = 'digitalPersönlich';
const ICONLISTELEMENT_MEHRWERTPLUS_LABEL = 'mehrwertPlus';
const ICONLISTELEMENT_TRANSPARENT_LABEL = 'tranparent';

const ICONLISTELEMENT_KBESTIMMO_LABEL = 'kBestImmo';
const ICONLISTELEMENT_KMARKTANALYSE_LABEL = 'kMarktanalyse';
const ICONLISTELEMENT_KANEINEMORT_LABEL = 'kAnEinemOrt';

const ICONLISTELEMENT_VKINTERESSENTEN_LABEL = 'vkInteressenten';
const ICONLISTELEMENT_VKTRANSPARENT_LABEL = 'vkTransparent';
const ICONLISTELEMENT_VKIMMODRIVE_LABEL = 'vkImmoDrive';

const ICONLISTELEMENT_SREALNEWSLETTER_LABEL = 'sRealNewsletter';
const ICONLISTELEMENT_SREALMAGAZIN_LABEL = 'sRealMagazin';
const ICONLISTELEMENT_SREALIMMOBLOG_LABEL = 'sRealImmoBlog';

export {
  ICONLISTELEMENT_BASIC_LABEL,
  ICONLISTELEMENT_MEHRWERT_LABEL,
  ICONLISTELEMENT_ALLINONE_LABEL,
  ICONLISTELEMENT_DIGITALPERSÖNLICH_LABEL,
  ICONLISTELEMENT_MEHRWERTPLUS_LABEL,
  ICONLISTELEMENT_TRANSPARENT_LABEL,
  ICONLISTELEMENT_KBESTIMMO_LABEL,
  ICONLISTELEMENT_KMARKTANALYSE_LABEL,
  ICONLISTELEMENT_KANEINEMORT_LABEL,
  ICONLISTELEMENT_VKINTERESSENTEN_LABEL,
  ICONLISTELEMENT_VKTRANSPARENT_LABEL,
  ICONLISTELEMENT_VKIMMODRIVE_LABEL,
  ICONLISTELEMENT_SREALNEWSLETTER_LABEL,
  ICONLISTELEMENT_SREALMAGAZIN_LABEL,
  ICONLISTELEMENT_SREALIMMOBLOG_LABEL,
};

const PHASE0 = '0';
const PHASE1_VERKAUF = '1vk';
const PHASE1_KAUF = '1k';
const PHASE2_VERKAUF_OHNE_AKTIVITAET = '2vk-a';
const PHASE2_VERKAUF_MIT_AKTIVITAET = '2vk-b';
const PHASE3_VERKAUF = '3vk';
const PHASE4_VERKAUF = '4vk';

const PHASE2_KAUF_SP_BEARBEITUNG = '2k-a';
const PHASE2_KAUF_SP_AKTIV = '2k-b';
const PHASE3_KAUF = '3k';
const PHASE4_KAUF = '4k';

export {
  PHASE0,
  PHASE1_VERKAUF,
  PHASE1_KAUF,
  PHASE2_VERKAUF_OHNE_AKTIVITAET,
  PHASE2_VERKAUF_MIT_AKTIVITAET,
  PHASE3_VERKAUF,
  PHASE4_VERKAUF,
  PHASE2_KAUF_SP_BEARBEITUNG,
  PHASE2_KAUF_SP_AKTIV,
  PHASE3_KAUF,
  PHASE4_KAUF,
};

const JUSTIMMO_IMMOBILIENART_WOHNUNG = 2;
const JUSTIMMO_IMMOBILIENART_HAUS = 3;
const JUSTIMMO_IMMOBILIENART_GRUNDSTUECK = 4;

export { JUSTIMMO_IMMOBILIENART_WOHNUNG, JUSTIMMO_IMMOBILIENART_HAUS, JUSTIMMO_IMMOBILIENART_GRUNDSTUECK };

const SP_WITHOUT_IMMOS_HEADLINE = 'Noch keine Angebote vorhanden';
const SP_WITHOUT_IMMOS_TEXT = 'Derzeit wurden noch keine passenden Immobilien zu Ihrem Suchprofil gefunden. Sobald wir die richtige Immobilie für Sie haben, erscheint der Treffer hier.';

export { SP_WITHOUT_IMMOS_HEADLINE, SP_WITHOUT_IMMOS_TEXT };

const JUSTIMMO_TYPE_FEDERAL_STATE_LABEL = 'federal_state';
const JUSTIMMO_TYPE_FEDERAL_STATE_ID = 0;

const JUSTIMMO_TYPE_POLITICAL_DISTRICT_LABEL = 'political_district';
const JUSTIMMO_TYPE_POLITICAL_DISTRICT_ID = 1;

const JUSTIMMO_TYPE_CITY_LABEL = 'city';
const JUSTIMMO_TYPE_CITY_ID = 2;

const JUSTIMMO_TYPE_MUNICIPALITY_LABEL = 'municipality';
const JUSTIMMO_TYPE_MUNICIPALITY_ID = 3;

const JUSTIMMO_TYPE_ZIP_LABEL = 'zip';
const JUSTIMMO_TYPE_ZIP_ID = 4;

const JUSTIMMO_TYPE_CITYZIP_LABEL = 'city_zip';
const JUSTIMMO_TYPE_CITYZIP_ID = 5;

export {
  JUSTIMMO_TYPE_FEDERAL_STATE_LABEL,
  JUSTIMMO_TYPE_FEDERAL_STATE_ID,
  JUSTIMMO_TYPE_POLITICAL_DISTRICT_LABEL,
  JUSTIMMO_TYPE_POLITICAL_DISTRICT_ID,
  JUSTIMMO_TYPE_CITY_LABEL,
  JUSTIMMO_TYPE_CITY_ID,
  JUSTIMMO_TYPE_MUNICIPALITY_LABEL,
  JUSTIMMO_TYPE_MUNICIPALITY_ID,
  JUSTIMMO_TYPE_ZIP_LABEL,
  JUSTIMMO_TYPE_ZIP_ID,
  JUSTIMMO_TYPE_CITYZIP_LABEL,
  JUSTIMMO_TYPE_CITYZIP_ID,
};

const WELCOME_MESSAGE_SUBJECT = 'Herzlich willkommen!';
export { WELCOME_MESSAGE_SUBJECT };

const JUSTIMMO_REALTY_SYSTEMTYPE_REALTY = 0;
const JUSTIMMO_REALTY_SYSTEMTYPE_RESIDENTIALPROJEKT = 1;
const JUSTIMMO_REALTY_SYSTEMTYPE_RESIDENTIALSUBUNIT = 2;
const JUSTIMMO_REALTY_SYSTEMTYPE_COMMERCIALPROJECT = 3;
const JUSTIMMO_REALTY_SYSTEMTYPE_COMMERCIALAREA = 4;

export {
  JUSTIMMO_REALTY_SYSTEMTYPE_REALTY,
  JUSTIMMO_REALTY_SYSTEMTYPE_RESIDENTIALPROJEKT,
  JUSTIMMO_REALTY_SYSTEMTYPE_RESIDENTIALSUBUNIT,
  JUSTIMMO_REALTY_SYSTEMTYPE_COMMERCIALPROJECT,
  JUSTIMMO_REALTY_SYSTEMTYPE_COMMERCIALAREA,
};
