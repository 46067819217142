import { useRef, useState } from 'react';
import Slider from 'react-slick';
import '../../styles/slick.min.css';
import '../../styles/simpleslider.scss';

/* DOCS
https://react-slick.neostack.com/docs/get-started
*/

function SimpleSlider({ settings, children, customDots, isDashboard, cssClasses }) {
  const sliderRef = useRef(null);

  const [customDotActiveIndex, setCustomDotActiveIndex] = useState(0);

  const handleGoTo = (index) => {
    setCustomDotActiveIndex(index);

    // set middle slider
    if (settings.slidesToShow === 2.5 && index === 2) {
      index = 1;
    }

    sliderRef.current.slickGoTo(index);
  };

  const handleSwipe = (direction) => {
    if (direction === 'left' && customDotActiveIndex < children.length - 1) {
      setCustomDotActiveIndex((customDotActiveIndex) => customDotActiveIndex + 1);
    }
    if (direction === 'right' && customDotActiveIndex > 0) {
      setCustomDotActiveIndex((customDotActiveIndex) => customDotActiveIndex - 1);
    }
  };

  return (
    <>
      <Slider {...settings} ref={sliderRef} onSwipe={handleSwipe} className={isDashboard ? 'h-80p ' : 'carousel-slider ' + cssClasses}>
        {children}
      </Slider>
      {customDots && children.length > 1 && (
        <ul className="slick-dots slick-customdots">
          {children.map((slide, index) => {
            return (
              <li key={index} className={index === customDotActiveIndex ? 'slick-active' : ''} onClick={() => handleGoTo(index)}>
                <button>{index}</button>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}

export default SimpleSlider;
