//*React
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

//*Components
import ContactAgentCard from '../components/Cards/ContactAgentCard';
import FinanzierungsCard from '../components/Cards/FinanzierungsCard';
import InfrastrukturCard from '../components/Cards/InfrastrukturCard.js';
import Header from '../components/Header/Header';
import ImmoFeatures from '../components/Immo/ImmoFeatures';
import Map from '../components/Immo/Map';
import Linkitem from '../components/Linkitems/Linkitem';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import ImmoDetailSkeleton from '../components/Skeletons/ImmoDetailSkeleton';
import ImmoWohneinheitenSkeleton from '../components/Skeletons/ImmoWohneinheitenSkeleton';
import MediaSlider from '../components/Sliders/MediaSlider';
import Table from '../components/Table';
import ImmoDetailPrint from './ImmoDetailPrint.js';
import ImmoWohneinheiten from '../components/Immo/ImmoWohneinheiten.js';

//*Icons
import IconDocument from '../components/Icons/IconDocument';
import IconImmoLive from '../components/Icons/IconImmoLive';
import IconPrint from '../components/Icons/IconPrint';
import IconProfile from '../components/Icons/IconProfile';
import IconShare from '../components/Icons/IconShare';
import IconStar from '../components/Icons/IconStar';
import IconHeart from '../components/Icons/IconHeart';
import IconVideo from '../components/Icons/IconVideo';
import IconCheckmarkRound from '../components/Icons/IconCheckmarkRound';
import IconNeubauprojekt from '../components/Icons/IconNeubauprojekt.js';

//*API
import { getRealtyFile } from '../api/JustImmo';

//*Reducers
import { setPageDialogOpenContactForm, setReasonContactForm } from '../reducers/contactInformation';
import { setImmoObjectRequest, setMessageObjectRequest, setPageDialogOpenObjectRequest, setRealtyId, setTenantId } from '../reducers/objectRequest';
//*Utils
import { anliegenOptions } from '../components/FormFields/util/formOptions';
import { downloadDoc } from '../util/generalUtils';
import { teaserTextParser } from '../util/teaserTextParser';
//*Constants
import { CONTACTFORM_ANLIEGEN_ALLG, MENU_KAUF, PHASE1_KAUF, PHASE2_KAUF_SP_AKTIV, JUSTIMMO_REALTY_SYSTEMTYPE_RESIDENTIALPROJEKT, JUSTIMMO_REALTY_SYSTEMTYPE_RESIDENTIALSUBUNIT } from '../constants';
//*Hooks
import { useFetchImmo } from '../hooks/immo/useFetchImmo';
import { useHandleCTAScroll } from '../hooks/immo/useHandleCTAScroll';
import { useHandleFavorite } from '../hooks/immo/useHandleFavorite';
//*Styles
import { useNavigate } from 'react-router-dom';
import InfoDialogUnfavourite from '../components/Dialogs/InfoDialogUnfavourite';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { setMenuType } from '../reducers/app';
import { setPageDialogOpenLoginForm, setResetFieldsLoginForm } from '../reducers/loginForm';
import '../styles/immodetail.scss';
import { getKaufpreis } from '../util/immoObjectParser';
import EnergieCard from '../components/Cards/EnergieCard.js';
import EnergieSkala from '../components/EnergieSkala.js';
import EnergieCardMobile from '../components/Cards/EnergieCardMobile.js';

function ImmoDetail() {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const { immo, showFullDesc, setShowFullDesc, showPrivate, showFullAddress, token, wohneinheiten } = useFetchImmo();
  const { opacity, display, scrollToFinanzierung } = useHandleCTAScroll();

  const { addToFavourites } = useHandleFavorite(immo);
  const { isMobile } = useHandleScreenResize();
  const [openRemoveFavDialog, setOpenRemoveFavDialog] = useState(false);

  /**
   * opens infodialog for unfavouriting
   */
  const handleOpenRemoveFavDialog = () => {
    setOpenRemoveFavDialog(!openRemoveFavDialog);
  };

  const handleMaklerAnfrage = () => {
    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_ALLG]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  const handleOpenObjectRequestForm = () => {
    dispatch(setRealtyId(immo.id));
    dispatch(setTenantId(parseInt(immo.tenant_id)));
    dispatch(setImmoObjectRequest(immo));
    dispatch(setMessageObjectRequest('Liebes s REAL-Team, ich möchte ein Exposé zur Immobilie ' + immo.catalogue_number + ' anfragen.'));
    dispatch(setPageDialogOpenObjectRequest(true));
  };

  const scrollToDocuments = async () => {
    const documentsArea = document.getElementById('documents-headline');
    if (documentsArea) {
      documentsArea.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const parseHTML = require('html-react-parser');

  const parseHTMLAndRemoveStyles = (html) => {
    return parseHTML(html.replace(/(<[^>]+) style=".*?"/g, '$1'));
  };

  const downloadDocJustImmo = async (file) => {
    try {
      const realtyFile = await getRealtyFile(immo.id, immo.tenant_id, file.storage_key, token);
      const url = window.URL.createObjectURL(realtyFile);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.original_filename;
      a.click();
    } catch (e) {
      //handle realty file error
      console.error('e', e);
    }
  };

  const goToDashboardOrLogin = () => {
    if (user.isLoggedIn) {
      dispatch(setMenuType(MENU_KAUF));
      navigate('../' + app.root + '/dashboard');
    } else {
      dispatch(setResetFieldsLoginForm());
      dispatch(setPageDialogOpenLoginForm(true));
    }
  };

  const handleFavFunc = () => {
    let favFound = user.favourites.find((f) => f?.id === (immo?.id).toString());

    if (!favFound) {
      addToFavourites(immo);
    } else {
      handleOpenRemoveFavDialog();
    }
  };

  const midIndexPrices = Math.ceil(immo.prices.length / 2);
  const firstHalfPrices = immo.prices.slice(0, midIndexPrices);
  const secondHalfPrices = immo.prices.slice(midIndexPrices);

  /**
   * checks if Expose exists
   * @returns Boolean
   */
  const checkForExpose = () => {
    let hasExpose = false;
    if (showPrivate && immo.documentsPrivate?.length > 0) {
      let isExpose = immo.documentsPrivate.find((doc) => doc.isExpose);
      if (isExpose) {
        hasExpose = true;
      }
    }

    return hasExpose;
  };

  // PRINT STUFF
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let furnishing = [];
  let condition = [];
  let energy = [];

  immo.featureList.forEach((feature) => {
    switch (feature.category) {
      case 'furnishing':
        furnishing.push({ value: feature.value, label: feature.label });
        break;
      case 'condition':
        condition.push({ value: feature.value, label: feature.label });
        break;
      case 'energy':
        energy.push({ value: feature.value, label: feature.label });
        break;
    }
  });

  const order = [
    'Bauart',
    'Bauweise',
    'Barrierefreiheit',
    'Ausbaustufe',
    'Dachform',
    'Räume',
    'Elektrik',
    'Energietyp',
    'Befeuerung',
    'Heizungsart',
    'Belüftung',
    'Urkunden',
    'Gebäudezertifikate',
    'Zustandsbewertung',
  ];

  const filteredCondition = condition.filter((item) => order.includes(item.label));

  const sortedCondition = filteredCondition.sort((a, b) => {
    return order.indexOf(a.label) - order.indexOf(b.label);
  });

  const midIndexCondition = Math.ceil(sortedCondition.length / 2);
  const firstHalfCondition = sortedCondition.slice(0, midIndexCondition);
  const secondHalfCondition = sortedCondition.slice(midIndexCondition);

  const midIndexAreas = Math.ceil(immo.areaList.length / 2);
  const firstHalfAreas = immo.areaList.slice(0, midIndexAreas);
  const secondHalfAreas = immo.areaList.slice(midIndexAreas);

  const totalFurnishing = furnishing[0]?.value.length || 0;
  const firstThirdIndex = Math.ceil(totalFurnishing / 3);
  const secondThirdIndex = Math.ceil((totalFurnishing - firstThirdIndex) / 2) + firstThirdIndex;

  const firstThirdFurnishing = furnishing[0]?.value.slice(0, firstThirdIndex);
  const secondThirdFurnishing = furnishing[0]?.value.slice(firstThirdIndex, secondThirdIndex);
  const thirdThirdFurnishing = furnishing[0]?.value.slice(secondThirdIndex);

  const skeletonColor = '#e4e4e4';

  if (immo.id === -1) {
    return <ImmoDetailSkeleton />;
  } else {
    return (
      <>
        <MetaTags
          title={immo.title}
          description={teaserTextParser(immo.description.replace(/(<([^>]+)>)/gi, ''), 160)}
          imageName={immo.imagesPublic[0] && !immo.imagesPublic[0].url ? immo.imagesPublic[0].url : 'mysreal_hero_immosuche.jpg'}
          imageFullPath={immo.imagesPublic[0] && !immo.imagesPublic[0].url ? true : false}
        />
        <Header cssClasses={immo.imagesPublic.length > 0 ? 'header--immodetail' : 'header--greenbg z-index-1'} />
        <MainContent id="immodetail">
          <ImmoDetailPrint ref={componentRef} immo={immo} />

          <div className={immo.imagesPublic.length > 0 ? '' : 'immo-subheader--no-images'}>
            <div className="mobile-hidden w-desktop--center">
              <div className="immodetail__headline">
                <h1 className="mb-0">{immo.title}</h1>
                {user.isLoggedIn && app.menuType === MENU_KAUF && app.menuId !== PHASE1_KAUF ? (
                  <IconHeart
                    color={immo.imagesPublic.length > 0 ? '#3679ec' : '#fff'}
                    cssClasses="immodetail__favicon"
                    filled={user.favourites.find((f) => f.id === immo.id.toString())}
                    handleClick={() => handleFavFunc()}
                  />
                ) : null}
                <IconPrint color={immo.imagesPublic.length > 0 ? '#3679ec' : '#fff'} cssClasses="immodetail__favicon" handleClick={() => handlePrint()} />
                <IconShare color={immo.imagesPublic.length > 0 ? '#3679ec' : '#fff'} cssClasses="immodetail__favicon" urlToSend={window.location.href} />
              </div>

              {immo.immolive?.immoLiveHighestBid > 0 && <IconImmoLive color={immo.imagesPublic.length > 0 ? '#1E3163' : '#fff'} cssClasses="immodetail--immolive" />}

              <p className="immodetail__objectnumber">Objektnummer {immo.catalogue_number}</p>
            </div>

            {immo.imagesPublic.length > 0 && (
              <div className="w-desktop--slider">
                <MediaSlider media={immo.imagesPublic} label={immo.system_type === JUSTIMMO_REALTY_SYSTEMTYPE_RESIDENTIALPROJEKT ? 'Neubauprojekt' : ''} labelIcon={<IconNeubauprojekt />} />
              </div>
            )}
          </div>
          <div className={`immodetail__headline desktop-hidden ${immo.imagesPublic.length > 0 ? 'w-desktop--center' : 'immo-subheader--no-images'}`}>
            <h1>{immo.title}</h1>
            <div className="immodetail__icons-mobile">
              {user.isLoggedIn && app.menuType === MENU_KAUF && app.menuId !== PHASE1_KAUF && (
                <IconHeart color={immo.imagesPublic.length > 0 ? '#3679ec' : '#fff'} filled={user.favourites.find((f) => f.id === immo.id.toString())} handleClick={() => handleFavFunc()} />
              )}
              <IconPrint color={immo.imagesPublic.length > 0 ? '#3679ec' : '#fff'} handleClick={() => handlePrint()} />
              <IconShare color={immo.imagesPublic.length > 0 ? '#3679ec' : '#fff'} cssClasses="" urlToSend={window.location.href} />
            </div>
            <p className="immodetail__objectnumber">Objektnummer {immo.catalogue_number}</p>
          </div>
          <div className="w-desktop--center">
            <ImmoFeatures immo={immo} showKaufpreis={true} immodetail={true} />
            <ContactAgentCard agent={immo.agent} objectNumber={immo.catalogue_number} urlBtnAnfrage={handleMaklerAnfrage} cssClasses="mt-200rem" />
          </div>

          <div className="immodetail__desc">
            <div className="w-desktop--center">
              <h2 className="mt-0">Objektbeschreibung</h2>
              {showFullDesc ? (
                <div>{parseHTMLAndRemoveStyles(immo.description)}</div>
              ) : (
                <div>
                  {parseHTMLAndRemoveStyles(immo.shortDescription)}
                  <span className="textlink" onClick={() => setShowFullDesc(true)}>
                    <br />
                    Weiterlesen
                  </span>
                </div>
              )}

              {immo.onlineViewing.length > 0 && (
                <>
                  {immo.onlineViewing.map((link) => {
                    return (
                      <Linkitem
                        key={link.id}
                        cssClasses="linkitem--einzeilig-desktop mt-400rem"
                        handleLink={() => window.open(link.url, '_blank', 'noopener,noreferrer')}
                        headline="Online-Besichtigung ansehen"
                        text="Machen Sie sich mit unserer online 360-Grad-Besichtigung ein Bild der Immobilie."
                        icon={<IconVideo />}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>

          {(immo.documentsPublic.length > 0 || (showPrivate && immo.documentsPrivate.length > 0)) && (
            <div className="light-teal-background">
              <div className="w-desktop--center">
                <h2 id="documents-headline" className="mt-0 mb-0 text-white">
                  immo-drive
                </h2>
                <h3 className="documents-subheadline text-white">
                  Dokumente zum Objekt: {immo.address.zip} {immo.address.city}
                </h3>
                <div className="elements2cols">
                  {(() => {
                    const exposeElement = showPrivate ? immo.documentsPrivate.filter((file) => file.isExpose) : [];
                    let restElements = [...immo.documentsPublic];
                    if (showPrivate) {
                      restElements = [...restElements, ...immo.documentsPrivate];
                    }
                    restElements = restElements.filter((file) => !file.isExpose);

										if(app.menuType === MENU_KAUF) {
											// only show activity files for Interessent
											restElements = restElements.filter((file) => file.isActivityFile && file.isActivityFile === true);
										}

                    const middleIndex = Math.ceil(restElements.length / 2);
                    const firstHalf = restElements.slice(0, middleIndex);
                    const secondHalf = restElements.slice(middleIndex);

                    return (
                      <>
                        {isMobile ? (
                          <>
                            <div className="expose-element elements2cols__col">
                              {exposeElement.map((file) => (
                                <Linkitem
                                  handleLink={async () => {
                                    await downloadDocJustImmo(file);
                                  }}
                                  icon={<IconDocument colorDetails="#1E3163" />}
                                  headline={file.isExpose ? 'Exposé' : file.headline}
                                  text={file.text}
                                  showArrow={false}
                                  key={file.storage_key}
                                  cssClasses={`elements2cols__col ${file.isExpose ? 'linkitem--blue' : 'linkitem-newblue'}`}
                                  dateOrState={file.date}
                                />
                              ))}
                            </div>
                            <div className="all-rest-elements linkitem-collection">
                              {firstHalf.concat(secondHalf).map((file) => (
                                <Linkitem
                                  handleLink={file.visibility === 1 ? async () => await downloadDocJustImmo(file) : () => downloadDoc(file.url, file.headline)}
                                  icon={<IconDocument colorDetails="#1E3163" />}
                                  headline={file.headline}
                                  text={file.text}
                                  showArrow={false}
                                  key={file.storage_key}
                                  cssClasses="elements2cols__col linkitem-newblue"
                                  dateOrState={file.date}
                                />
                              ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="elements2cols__col">
                              <div className="expose-element">
                                {exposeElement.map((file) => (
                                  <Linkitem
                                    handleLink={async () => {
                                      await downloadDocJustImmo(file);
                                    }}
                                    icon={<IconDocument colorDetails="#1E3163" />}
                                    headline={file.isExpose ? 'Exposé' : file.headline}
                                    text={file.text}
                                    showArrow={false}
                                    key={file.storage_key}
                                    cssClasses={` ${file.isExpose ? 'linkitem--blue' : 'linkitem-newblue'}`}
                                    dateOrState={file.date}
                                  />
                                ))}
                              </div>
                              <div className="first-half-elements linkitem-collection">
                                {firstHalf.map((file) => (
                                  <Linkitem
                                    handleLink={file.visibility === 1 ? async () => await downloadDocJustImmo(file) : () => downloadDoc(file.url, file.headline)}
                                    icon={<IconDocument colorDetails="#1E3163" />}
                                    headline={file.headline}
                                    text={file.text}
                                    showArrow={false}
                                    key={file.storage_key}
                                    cssClasses="elements2cols__col linkitem-newblue"
                                    dateOrState={file.date}
                                  />
                                ))}
                              </div>
                            </div>

                            <div className="second-half-elements elements2cols__col linkitem-collection">
                              {secondHalf.map((file) => (
                                <Linkitem
                                  handleLink={file.visibility === 1 ? async () => await downloadDocJustImmo(file) : () => downloadDoc(file.url, file.headline)}
                                  icon={<IconDocument colorDetails="#1E3163" />}
                                  headline={file.headline}
                                  text={file.text}
                                  showArrow={false}
                                  key={file.storage_key}
                                  cssClasses="elements2cols__col linkitem-newblue"
                                  dateOrState={file.date}
                                />
                              ))}
                            </div>
                          </>
                        )}
                      </>
                    );
                  })()}

                  {immo.imagesPrivate.map((img) => {
                    return (
                      <Linkitem
                        handleLink={async () => {
                          await downloadDocJustImmo(img);
                        }}
                        icon={<IconDocument colorDetails="#1E3163" />}
                        headline={img.headline}
                        text={img.text}
                        showArrow={false}
                        key={img.storage_key}
                        cssClasses="elements2cols__col linkitem-newblue"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div className="w-desktop--center">
            <div className="immodetail__maps">{/* TODO: add google maps */}</div>
            <h2>Merkmale</h2>

            {(sortedCondition?.length > 0 || energy?.length > 0) && <h3>Zustand & Energie</h3>}

            {sortedCondition?.length > 0 && (
              <>
                <div className="immodetail__table--wrapper mb-200rem">
                  <div className="immodetail__table-wrapper immodetail-desktop">
                    <div className="immodetail__table">
                      <Table list={firstHalfCondition} />
                    </div>
                    {secondHalfCondition?.length > 0 && (
                      <div className="immodetail__table">
                        <Table list={secondHalfCondition} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="immodetail__table immodetail-mobile mb-200rem">
                  <Table list={sortedCondition} htmlParser={true} />
                </div>
              </>
            )}

            {energy?.length > 0 && (
              <>
                <div className="card--energy-wrapper">
                  <div className="card--energy-wrapper__cards d-flex">
                    <EnergieCard energy={energy} category="fGEE Klasse" />
                    <EnergieCard energy={energy} category="Heizwärmeklasse" />
                  </div>
                  <EnergieSkala />
                </div>
              </>
            )}

            {/* 
						{ energy?.length > 0 && (
							<>
								{isMobile ? (
									<EnergieCardMobile
										energy={energy}
										value1={energy.find((item) => item.label === 'fGEE Klasse')?.value}
										value2={energy.find((item) => item.label === 'Heizwärmebedarf')?.value}
										value3={energy.find((item) => item.label === 'Heizwärmeklasse')?.value}
										label1="fGEE"
										label2="Heizwärmebedarf"
									/>
								) : (
									<>
										<div className="d-flex card--energy-wrapper">
											<EnergieCard energy={energy} category="fGEE Klasse"  />
											<EnergieCard energy={energy} category="Heizwärmeklasse" />
										</div>
										<EnergieSkala />
									</>
								) }
							</>
						)}
						*/}

            {immo.areaList && immo.areaList.length > 0 && (
              <>
                <h3>Flächen und Zimmer</h3>
                <div className="immodetail__table--wrapper mb-200rem">
                  <div className="immodetail__table-wrapper immodetail-desktop">
                    <div className="immodetail__table">
                      <Table list={firstHalfAreas} />
                    </div>
                    <div className="immodetail__table">
                      <Table list={secondHalfAreas} />
                    </div>
                  </div>
                </div>
                <div className="immodetail__table immodetail-mobile">
                  <Table list={immo.areaList} htmlParser={true} />
                </div>
              </>
            )}

            {furnishing[0].value.length > 0 && (
              <>
                <h3>Ausstattungskriterien</h3>
                {isMobile ? (
                  <ul className="immodetail__list mb-200rem">
                    {furnishing[0].value.map((item, index) => (
                      <li key={index} className="d-flex mb-50rem">
                        <IconCheckmarkRound cssClasses="mr-50rem" />
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="d-flex d-flex--gap-5p">
                    <ul className="immodetail__list mb-200rem">
                      {firstThirdFurnishing.map((item, index) => (
                        <li key={index} className="d-flex mb-50rem">
                          <IconCheckmarkRound cssClasses="mr-50rem" />
                          {item}
                        </li>
                      ))}
                    </ul>
                    <ul className="immodetail__list mb-200rem">
                      {secondThirdFurnishing.map((item, index) => (
                        <li key={index} className="d-flex mb-50rem">
                          <IconCheckmarkRound cssClasses="mr-50rem" />
                          {item}
                        </li>
                      ))}
                    </ul>
                    <ul className="immodetail__list mb-200rem">
                      {thirdThirdFurnishing.map((item, index) => (
                        <li key={index} className="d-flex mb-50rem">
                          <IconCheckmarkRound cssClasses="mr-50rem" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}

            {wohneinheiten.length > 0 && (
              <>
                {immo.system_type === JUSTIMMO_REALTY_SYSTEMTYPE_RESIDENTIALPROJEKT ? (
                  <h2>Alle Einheiten dieses Neubauprojekts</h2>
                ) : immo.system_type === JUSTIMMO_REALTY_SYSTEMTYPE_RESIDENTIALSUBUNIT ? (
                  <h2>Weitere Einheiten dieses Neubauprojekts</h2>
                ) : null}

                <ImmoWohneinheiten isSearch={false} wohneinheiten={wohneinheiten} />
              </>
            )}
          </div>

          <div className="gray-background">
            <div className="immodetail__location-wrapper mb-150rem w-desktop--center">
              <h2 className="mt-150rem mb-200rem">{showFullAddress ? 'Lagebeschreibung' : 'Wohnareal'}</h2>
              <div className={showFullAddress ? 'immodetail__location immodetail__location--withgooglemaps' : 'immodetail__location'}>
                <div className="immodetail__location--address">
                  In {immo.address.zip} {immo.address.city}
                  {showFullAddress && (
                    <>
                      <br />
                      <span>
                        {immo.address.street} {immo.address.number}
                        {immo.address.door && (
                          <>
                            {'/'}
                            {immo.address.door}
                          </>
                        )}
                        {immo.address.entrance && (
                          <>
                            {'/'}
                            {immo.address.entrance}
                          </>
                        )}
                      </span>
                    </>
                  )}
                </div>

                {showFullAddress ? <Map coordinates={immo.address.geo_coordinates} /> : <Map postCode={immo.address.zip} />}
              </div>
              {immo.infrastructureList?.length > 0 ? (
                <>
                  <h2>Infrastruktur</h2>
                  <div className="immodetail__table-wrapper immodetail-desktop">
                    <div className="immodetail__table">
                      <Table list={immo.infrastructureList[0]} />
                    </div>

                    <div className="immodetail__table">
                      <Table list={immo.infrastructureList[1]} />
                    </div>
                  </div>
                  <div className="immodetail__table immodetail-mobile">
                    <Table list={immo.infrastructureList.flat()} htmlParser={true} />
                  </div>
                </>
              ) : null}
              <InfrastrukturCard />
            </div>
          </div>
          <div className="pt-0 w-desktop--center">
            <div className="immodetail__price-agent--wrapper">
              {immo.prices && immo.prices.length > 0 && (
                <>
                  <div className="immodetail__price--wrapper">
                    <h2>Preise</h2>
                    <div className="immodetail__table-wrapper immodetail-desktop">
                      <div className="immodetail__table">
                        <Table list={firstHalfPrices} />
                      </div>
                      <div className="immodetail__table">
                        <Table list={secondHalfPrices} />
                      </div>
                    </div>
                  </div>
                  <div className="immodetail__table immodetail-mobile">
                    <Table list={immo.prices} htmlParser={true} />
                  </div>
                </>
              )}
            </div>
            <h2 className="mt-300rem" id="finanzierung">
              Finanzierung
            </h2>
            <FinanzierungsCard />
          </div>

          {/* fixed bar */}
          <div className="immodetail--fixed-wrapper" style={{ opacity: opacity, display: display }}>
            <div className={`${!isMobile ? 'w-desktop--center' : ''}`}>
              <div className="immodetail--fixed text-white">
                <div>
                  <h2 className="text-white">Kaufpreis</h2>
                  <span className="immodetail__price">{getKaufpreis(immo)}</span>
                </div>

                <div className="d-flex d-flex--gap align-items-center">
                  {checkForExpose() === true ? (
                    <button className="button button--white" onClick={() => scrollToDocuments()}>
                      Zum Exposé
                    </button>
                  ) : showPrivate && immo.agent ? (
                    <a className="button button--white mt-10" href={'mailto:' + immo.agent?.email} title={`E-Mail an ${immo.agent?.first_name} ${immo.agent?.last_name}`}>
                      {immo.agent?.gender === 'Herr' ? 'Makler kontaktieren' : 'Maklerin kontaktieren'}
                    </a>
                  ) : (
                    <button className="button button--white" onClick={() => handleOpenObjectRequestForm()}>
                      Immobilie anfragen
                    </button>
                  )}
                  <span className="button button--white-outline mobile-hidden w-100 d-flex flex-col align-items-cente" onClick={scrollToFinanzierung}>
                    Finanzierung anfragen
                  </span>
                </div>
              </div>

              {/* only show link to Dashboard if:
                User is comming from Angebotsmail +
                is MenuPhase2 (because there is no Dashboard in Phase3 and Phase4)
              */}
              {window.location.pathname.includes('/webangebot/') ? (
                <>
                  {!isMobile ? (
                    <div>
                      <Linkitem
                        cssClasses="linkitem--einzeilig-desktop"
                        handleLink={() => {
                          goToDashboardOrLogin();
                        }}
                        headline={`Im Dashboard finden Sie alle Ihre Angebote, Suchprofile und mehr.`}
                        text={`${user.isLoggedIn && app.menuId === PHASE2_KAUF_SP_AKTIV ? "Hier geht's zum Dashboard!" : "Hier geht's zum Login!"}`}
                        icon={<IconProfile color={'#3679ec'} />}
                      />
                    </div>
                  ) : (
                    <div className="p-100rem mb-10 text-white">
                      <p>Im Dashboard finden Sie alle Ihre Angebote, Suchprofile und mehr.</p>
                      <p className="textlink mt-10 font-110 font-weight-600 text-white" onClick={() => goToDashboardOrLogin()}>
                        {user.isLoggedIn && app.menuId === PHASE2_KAUF_SP_AKTIV ? "Hier geht's zum Dashboard!" : "Hier geht's zum Login!"}
                      </p>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </MainContent>
        <InfoDialogUnfavourite retailObject={immo} handleClose={handleOpenRemoveFavDialog} open={openRemoveFavDialog} />
      </>
    );
  }
}

export default ImmoDetail;
