import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//*Components
import FinanzierungsCard from '../components/Cards/FinanzierungsCard';
import ImmoCard from '../components/Cards/ImmoCard';
import KaufanbotCard from '../components/Cards/KaufanbotCard';
import MarktinfoLightCard from '../components/Cards/MarktinfoLightCard';
import SearchProfileWithouthImmos from '../components/Cards/SearchProfileWithouthImmos';
import Carousel from '../components/Carousel/Carousel';
import FilterMenu from '../components/FilterMenu';
import Header from '../components/Header/Header';
import IconSearch from '../components/Icons/IconSearch';
import RecommendationLinkitem from '../components/Linkitems/RecommondationLinkitem';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsTeaserCards from '../components/News/NewsTeaserCards';
import headerImage from '../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.2.jpg';
import headerImageMobile from '../assets/images/mysreal_HerotemplateMobil_593x950px_Neu2024-_0004_s-REAL-Sujets-2023-Webhero-16zu9-1.13.jpg';

//*Icons
//*Utils
import LinkToSearchProfiles from '../components/LinkToSearchProfiles';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchSearchprofiles } from '../hooks/searchprofiles/useFetchSearchprofiles';
import CarouselNew from '../components/Carousel/CarouselNew';

function DashboardKPhase2({ filterMenu }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const { getSearchprofilesMenu } = useFetchSearchprofiles();
  const [suchprofileMenu, setSuchprofileMenu] = useState(null);

  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    setSuchprofileMenuState();
  }, [user]);

  const setSuchprofileMenuState = () => {
    setSuchprofileMenu(getSearchprofilesMenu());
  };

  const [activeSuchprofileIndex, setActiveSuchprofileIndex] = useState(0);
  const handleSetActiveSuchprofileIndex = (event) => {
    let index = parseInt(event.target.dataset.index);
    setActiveSuchprofileIndex(index);
  };

  return (
    <>
      <MetaTags title={'Dashboard | Die Online-Plattform für Ihre Immobilie'} imageName={'mysreal_hero_dashboard-kauf.jpg'} />
      <Header cssClasses="header--default header--fitcontent header-overflowhidden">
        <img src={!isMobile ? headerImage : headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
        <div className="header__content-wrapper" id="header__suchprofile">
          <div className="header__suchprofile--top">
            <h1 className="text-white">Meine Angebote</h1>
            <div className="header__suchprofile--topfiltermenu mobile-hidden">
              {suchprofileMenu && (
                <FilterMenu
                  categories={suchprofileMenu}
                  activeIndex={activeSuchprofileIndex}
                  handleClick={handleSetActiveSuchprofileIndex}
                  cssClasses="filtermenu--inverted"
                  cutside={isMobile ? 'none' : 'right'}
                />
              )}
              <button className="button button--white position-relative button--round" style={{ padding: '0.6rem' }} onClick={() => navigate('../immobiliensuche')}>
                <IconSearch color="#2870ED" cssClasses={'position-center'} />
              </button>
            </div>

            <div className="header__suchprofile--topfiltermenu desktop-hidden">
              <button className="button button--white position-relative button--round" style={{ padding: '0.6rem' }} onClick={() => navigate('../immobiliensuche')}>
                <IconSearch color="#2870ED" cssClasses={'position-center'} />
              </button>
            </div>
            <div className="header__suchprofile--topfiltermenu desktop-hidden">
              {suchprofileMenu && (
                <FilterMenu
                  categories={suchprofileMenu}
                  activeIndex={activeSuchprofileIndex}
                  handleClick={handleSetActiveSuchprofileIndex}
                  cssClasses="filtermenu--inverted"
                  cutside={isMobile ? 'none' : 'right'}
                />
              )}
            </div>
          </div>
        </div>
        {suchprofileMenu &&
          suchprofileMenu.map((sp, index) => {
            let spMenuClass = 'header__suchprofile--immos';
            if (sp.key !== activeSuchprofileIndex) spMenuClass += ' is-hidden';

            let immos = sp.immos?.filter((immo) => immo.id);

            return (
              <div key={index}>
                {immos.length > 0 ? (
                  <CarouselNew cssClasses={spMenuClass} isMobile={isMobile}>
                    {immos.map((immo) => {
                      let status = '';
                      if (immo.activities.some((activity) => activity.id === '1591')) {
                        status = 'aktiv';
                      } else if (immo.activities.some((activity) => activity.id === '1594')) {
                        status = 'abgeschlossen';
                      }
                      return <ImmoCard retailObject={immo} key={immo.id} status={status} />;
                    })}
                  </CarouselNew>
                ) : (
                  <CarouselNew cssClasses={spMenuClass} isMobile={isMobile}>
                    <SearchProfileWithouthImmos cssClasses={spMenuClass} />
                  </CarouselNew>
                )}
              </div>
            );
          })}

        <div className="header__content-wrapper mt-0 mobile-hidden" id="header__suchprofile">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <div className={`header__suchprofile__links d-flex flex-row align-items-center pb-100rem ${isMobile && 'w-100 pt-200rem'} ${!isMobile && 'w-60 pt-50rem'}`}>
              <span onClick={() => navigate('../angebote')} className="w-100 textlink text-white text-nodecoration text-center hover-underline">
                Alle Angebote {!isMobile ? 'anzeigen' : ''}
              </span>
              <LinkToSearchProfiles cssClasses="w-100 textlink text-white text-nodecoration text-center hover-underline" linkText={'Suchprofile bearbeiten'} />
            </div>
          </div>
        </div>
      </Header>

      <MainContent>
        <div className="w-desktop--center">
          <h2 className="mt-300rem text-center--mobile">Immobilienkauf</h2>
          <FinanzierungsCard />
          <KaufanbotCard cssClasses={'mt-400rem mb-400rem'} />
        </div>

        <div>
          <div className="pt-0rem w-desktop--center">
            <MarktinfoLightCard cssClasses={'bg-white'} forKäufer={true} />
            <RecommendationLinkitem cssClasses="mb-0 mt-300rem" />
          </div>
        </div>

        <NewsTeaserCards cssClasses="mt-200rem w-desktop--slider" />
      </MainContent>
    </>
  );
}

export default DashboardKPhase2;
