import { useEffect, useState } from 'react';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import FilterMenu from '../components/FilterMenu';
import Card from '../components/Cards/Card';
import { Link } from 'react-router-dom';
import IconBarchart from '../components/Icons/IconBarchart';
import LineChartNew from '../components/Charts/LineChartNew';
import { NEW_STATS_FILTER_AKTUELLEWOCHE, NEW_STATS_FILTER_GESAMT, PHASE3_VERKAUF, STATS_FILTER_GESAMT } from '../constants';
import moment from 'moment';
import { useFetchStats } from '../hooks/stats/useFetchStats';
import { DateRangePicker } from 'rsuite';
import '../styles/rsuite.scss';
import { useSelector } from 'react-redux';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';
const { afterToday } = DateRangePicker;

function Statistiken() {
  const { getData, inseratKlicksData, clicksBySourceData, verschickteAngeboteData, besichtigungenData, filterData, startDatum, endDatum, dataError } = useFetchStats();
  const filterMenuStats = [NEW_STATS_FILTER_AKTUELLEWOCHE, NEW_STATS_FILTER_GESAMT];
  const [verschickteAngeboteFromSuchprofil, setVerschickteAngeboteFromSuchprofil] = useState(0);
  const [verschickteAngeboteFromOther, setVerschickteAngeboteFromOther] = useState(0);
  const [activeFilterIndexStats, setActiveFilterIndexStats] = useState(NEW_STATS_FILTER_GESAMT.key);
  const [activityPeriod, setActivityPeriod] = useState(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(null);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const [value, setValue] = useState([]);
  const { marketingActivities, marketingActivitiesLoaded } = useHandleUserRealtyActive();
  const { isMobile } = useHandleScreenResize();
  const app = useSelector((state) => state.app);
  const handleSetActiveFilterIndexStats = (event) => {
    let index = event.target.dataset.index;
    if (index === '0') {
      filterData([moment().subtract(1, 'week'), moment()]);
    } else if (index === '1') {
      filterData(null);
    }
    setSelectedTimeFrame(null);
    setActiveFilterIndexStats(index);
    setSelectionStart(null);
    setSelectionEnd(null);
    setValue([]);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (startDatum && endDatum) {
      const startDatumAktivitäten = new Date(startDatum);
      const endDatumAktivitäten = new Date(endDatum);
      const activityPeriodTime = endDatumAktivitäten.getTime() - startDatumAktivitäten.getTime();
      const activityPeriod = activityPeriodTime / (1000 * 3600 * 24);
      setActivityPeriod(activityPeriod);
    }
  }, [startDatum, endDatum]);

  const handleDateSelection = (date) => {
    const isCurrentWeek = moment(date).isSame(new Date(), 'week');
    const firstDayofWeek = moment(date).startOf('week').toDate();
    const lastDayofWeek = moment(date).endOf('week').toDate();
    if (!selectionStart) {
      setSelectionStart(firstDayofWeek);
    } else {
      if (isCurrentWeek) {
        setSelectionEnd(new Date());
      } else {
        setSelectionEnd(lastDayofWeek);
      }
    }
  };

  useEffect(() => {
    if (selectedTimeFrame) {
      filterData([moment(selectedTimeFrame[0]), moment(selectedTimeFrame[1])]);
    }
  }, [selectedTimeFrame]);

  useEffect(() => {
    console.log(app);
    console.log(app.menuId === PHASE3_VERKAUF);
  }, []);

  useEffect(() => {
    if (selectionStart && selectionEnd) {
      setSelectedTimeFrame([selectionStart, selectionEnd]);
      setActiveFilterIndexStats('3');
      setSelectionStart(null);
      setSelectionEnd(null);
    }
  }, [selectionStart, selectionEnd]);

  useEffect(() => {
    if (verschickteAngeboteData) {
      setVerschickteAngeboteFromSuchprofil(verschickteAngeboteData.filter((item) => item.type === 'JustImmo').reduce((acc, item) => acc + item.value, 0));
      setVerschickteAngeboteFromOther(verschickteAngeboteData.filter((item) => item.type !== 'JustImmo').reduce((acc, item) => acc + item.value, 0));
    }
  }, [verschickteAngeboteData]);

  return (
    <>
      <MetaTags title={'Statistiken | Die Online-Plattform für Ihre Immobilie'} />
      <HeaderSmallWithIcon icon={<IconBarchart />} iconActive={true} headlineDesktop="Statistiken" cssClasses="header--darkbluebg mb-0" />
      <MainContent cssClasses={!isMobile ? 'w-desktop--center' : ''}>
        {!dataError && (
          <div className="statistic-card__filterarea mt-400rem">
            <h4>Zeitraum filtern</h4>
            <div>
              <FilterMenu categories={filterMenuStats} activeIndex={activeFilterIndexStats} handleClick={handleSetActiveFilterIndexStats} />
              <span className="rs-picker-wrapper">
                <DateRangePicker
                  appearance="subtle"
                  showOneCalendar
                  defaultValue={null}
                  defaultCalendarValue={[moment().subtract(2, 'week').toDate(), moment().subtract(1, 'week').toDate()]}
                  calendarSnapping={false}
                  showHeader={false}
                  editable={false}
                  isoWeek
                  cleanable={false}
                  value={selectedTimeFrame}
                  hoverRange="week"
                  shouldDisableDate={afterToday()}
                  ranges={[]}
                  onOpen={() => {
                    setSelectionStart(null);
                    setSelectionEnd(null);
                    setSelectedTimeFrame(null);
                  }}
                  onSelect={(date) => {
                    handleDateSelection(date);
                  }}
                  onChange={(date) => {
                    setValue(date);
                  }}
                  className={activeFilterIndexStats === '3' ? 'active' : ''}
                  placement={'auto'}
                />
              </span>
            </div>
          </div>
        )}

        <Card cardStyle="customContent" cssClasses="p-150rem bg-grey">
          <Card cardStyle="customContent" cssClasses={'bg-white with-shadow p-100rem'}>
            <LineChartNew chartData={inseratKlicksData} dataError={dataError} />
          </Card>

          {clicksBySourceData && clicksBySourceData.length > 0 && (
            <>
              <h3>Klicks auf Inserat nach Quellen</h3>
              <div className="sources-data-container">
                <br />
                {clicksBySourceData.map((source, index) => {
                  return (
                    <div key={index}>
                      <span className="text-bold">{source.sum}</span> <span>{source.name}</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {!isMobile && !dataError ? (
            <>
              <div className={'elements2cols mt-300rem gap-200rem'}>
                <Card cardStyle="customContent" cssClasses="bg-white with-shadow flex-1 pl-200rem pr-200rem">
                  <h4>Besichtigungen gesamt </h4>
                  <p className="text-center font-350 text-bold">{besichtigungenData && besichtigungenData.length > 0 ? besichtigungenData.reduce((acc, curr) => acc + curr.value, 0) : 0}</p>
                  <p className="text-center">Besichtigungen</p>
                </Card>

                <Card cardStyle="customContent" cssClasses="bg-white with-shadow flex-1 pl-200rem pr-200rem">
                  <h4>Verschickte Angebote gesamt</h4>
                  <p className="text-center font-350 text-bold">
                    {verschickteAngeboteData && verschickteAngeboteData.length > 0 ? verschickteAngeboteData.reduce((acc, curr) => acc + curr.value, 0) : 0}
                  </p>
                  <p className="text-center">verschickte Angebote</p>
                </Card>
              </div>

              <div className={'elements2cols gap-200rem mb-300rem'}>
                <div className="flex-1 pl-400rem pr-400rem">
                  <h4 className="mt-0">Besichtigungen</h4>
                  <span>
                    <Link to={app.root + '/besichtigungen'} className="textlink">
                      Hier finden Sie die Detailseite
                    </Link>{' '}
                    mit allen Besichtigungen Ihrer Immobilie.
                  </span>
                </div>
                <div className="flex-1 pl-400rem pr-400rem">
                  <h4 className="mt-0">Verschickte Angebote nach Quellen</h4>
                  <div>
                    <span className="text-bold">{verschickteAngeboteFromSuchprofil}</span> <span>Angebote aus Suchprofiltreffern</span>
                  </div>
                  <div>
                    <span className="text-bold">{verschickteAngeboteFromOther}</span> <span>Andere</span>
                  </div>
                </div>
              </div>

              {app.menuId === PHASE3_VERKAUF && (
                <>
                  <div className={'elements2cols mt-300rem gap-200rem'}>
                    <Card cardStyle="customContent" cssClasses="bg-white with-shadow flex-1 pl-200rem pr-200rem">
                      <h4>Vermarktungsaktivitäten gesamt</h4>
                      <p className="text-center font-350 text-bold">{marketingActivitiesLoaded && marketingActivities ? marketingActivities : 0}</p>
                      <p className="text-center">Vermarktungsaktivitäten</p>
                    </Card>

                    <Card cardStyle="customContent" cssClasses="bg-white with-shadow flex-1 pl-200rem pr-200rem">
                      <h4>Tage in aktiver Vermarktung gesamt</h4>

                      <p className="text-center font-350 text-bold">{activityPeriod ? activityPeriod : 0}</p>
                      <p className="text-center">Tage in aktiver Vermarktung</p>
                    </Card>
                  </div>
                </>
              )}
            </>
          ) : isMobile && !dataError ? (
            <>
              <div className={'elements2cols mt-300rem '}>
                <Card cardStyle="customContent" cssClasses="bg-white with-shadow flex-1 pl-200rem pr-200rem">
                  <h4>Besichtigungen gesamt </h4>
                  <p className="text-center font-350 text-bold">{besichtigungenData && besichtigungenData.length > 0 ? besichtigungenData.reduce((acc, curr) => acc + curr.value, 0) : 0}</p>
                  <p className="text-center">Besichtigungen</p>
                </Card>
                <div className="flex-1 mb-200rem">
                  <h4 className="mt-0">Besichtigungen</h4>
                  <span>
                    <Link to={app.root + '/besichtigungen'} className="textlink">
                      Hier finden Sie die Detailseite
                    </Link>{' '}
                    mit allen Besichtigungen Ihrer Immobilie.
                  </span>
                </div>

                <Card cardStyle="customContent" cssClasses="bg-white with-shadow flex-1 pl-200rem pr-200rem">
                  <h4>Verschickte Angebote gesamt</h4>
                  <p className="text-center font-350 text-bold">
                    {verschickteAngeboteData && verschickteAngeboteData.length > 0 ? verschickteAngeboteData.reduce((acc, curr) => acc + curr.value, 0) : 0}
                  </p>
                  <p className="text-center">verschickte Angebote</p>
                </Card>

                <div className="flex-1 ">
                  <h4 className="mt-0">Verschickte Angebote nach Quellen</h4>
                  <div>
                    <span className="text-bold">{verschickteAngeboteFromSuchprofil}</span> <span>Angebote aus Suchprofiltreffern</span>
                  </div>
                  <div>
                    <span className="text-bold">{verschickteAngeboteFromOther}</span> <span>Andere</span>
                  </div>
                </div>
              </div>
              {app.menuId === PHASE3_VERKAUF && (
                <>
                  <div className={'elements2cols mt-300rem gap-200rem'}>
                    <Card cardStyle="customContent" cssClasses="bg-white with-shadow flex-1 pl-200rem pr-200rem">
                      <h4>Vermarktungsaktivitäten gesamt</h4>
                      <p className="text-center font-350 text-bold">{marketingActivitiesLoaded && marketingActivities ? marketingActivities : 0}</p>
                      <p className="text-center">Vermarktungsaktivitäten</p>
                    </Card>

                    <Card cardStyle="customContent" cssClasses="bg-white with-shadow flex-1 pl-200rem pr-200rem">
                      <h4>Tage in aktiver Vermarktung gesamt</h4>

                      <p className="text-center font-350 text-bold">{activityPeriod ? activityPeriod : 0}</p>
                      <p className="text-center">Tage in aktiver Vermarktung</p>
                    </Card>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </Card>
      </MainContent>
    </>
  );
}

export default Statistiken;
